import React, { useState, useEffect } from 'react';
import './Parceiros.css'

const App = () => {
  const lojas = [
    // {
    //   estado: "RJ",
    //   cidade: "Rio de Janeiro",
    //   nome: "Loja B",
    //   endereco: "Av. Exemplo, 456",
    //   telefone: "(21) 1234-5678",
    //   whatsapp: "(21) 91234-5678",
    //   cep: "20000-000",
    // },

    // BOITUVA
    {
        estado: "SP",
        cidade: "Boituva",
        nome: "ARMAZÉM FLORA",
        rua: "R. Cel Eugênio Motta",
        numero: "521 - Loja 43",
        bairro: "Centro",
        cep: "18550-103",
        telefone: "(15) 99631-0087",
        whatsapp: "",
    },
    {
        estado: "SP",
        cidade: "Boituva",
        nome: "PROMEL EMPÓRIO",
        rua: "R. Coronel Arruda Botelho",
        numero: "291",
        bairro: "Vila Ferriello",
        cep: "18550-000",
        telefone: "(15) 3316-5606",
        whatsapp: "",
    },
    {
        estado: "SP",
        cidade: "Boituva",
        nome: "DELICATTA",
        rua: "Av. Brasil",
        numero: "535",
        bairro: "Parque Res Esplanada",
        cep: "18550-000",
        telefone: "",
        whatsapp: "",
    },
    {
        estado: "SP",
        cidade: "Boituva",
        nome: "EMPÓRIO DOS PÃES",
        rua: "R. Zélia de Lima Rosa",
        numero: "489",
        bairro: "Recanto das Primaveras",
        cep: "18550-000",
        telefone: "",
        whatsapp: "",
    },
    {
        estado: "SP",
        cidade: "Boituva",
        nome: "PADARIA ANDREAS",
        rua: "R. Zélia de Lima Rosa",
        numero: "1244",
        bairro: "Portal Ville Azaleia",
        cep: "18552-320",
        telefone: "",
        whatsapp: "",
    },
    {
        estado: "SP",
        cidade: "Boituva",
        nome: "CAFETERIA MORETTI",
        rua: "Av. Ver. José Ângelo Biagion",
        numero: "660",
        bairro: "AC",
        cep: "18550-000",
        telefone: "",
        whatsapp: "",
    },
    {
        estado: "SP",
        cidade: "Boituva",
        nome: "LIGUE LINDOYA",
        rua: "",
        numero: "",
        bairro: "",
        cep: "",
        telefone: "",
        whatsapp: "",
    },

    // CAPIVARI
    {
        estado: "SP",
        cidade: "Capivari",
        nome: "ARMAZÉM DO SÍTIO",
        rua: "R. Arthur Corrêa de Toledo",
        numero: "S/N",
        bairro: "Bairro Pipeiro",
        cep: "13360-000",
        telefone: "(15) 99456-6648",
        whatsapp: "",
    },
    // {
    //     estado: "SP",
    //     cidade: "Capivari",
    //     nome: "EMPÓRIO VILLA SANTO ANTONIO",
    //     rua: "R. XV de Novembro",
    //     numero: "377",
    //     bairro: "Centro",
    //     cep: "13360-000",
    //     telefone: "(19) 3492-7467",
    //     whatsapp: "",
    // },
    {
        estado: "SP",
        cidade: "Capivari",
        nome: "PADARIA & CONFEITARIA ANGELIN",
        rua: "Av. José Annicchino",
        numero: "1843",
        bairro: "Vila Bela vista",
        cep: "13360-000",
        telefone: "",
        whatsapp: "",
    },
    {
        estado: "SP",
        cidade: "Capivari",
        nome: "SCOMPARIM SABORES DE MINAS",
        rua: "Av. Brigadeiro Faria Lima",
        numero: "614",
        bairro: "Jd. Santa maria",
        cep: "13369-040",
        telefone: "",
        whatsapp: "",
    },
    {
        estado: "SP",
        cidade: "Capivari",
        nome: "PADARIA BRESSIANI",
        rua: "R. Tiradentes",
        numero: "292",
        bairro: "Centro",
        cep: "13360-000",
        telefone: "",
        whatsapp: "",
    },
    {
        estado: "SP",
        cidade: "Capivari",
        nome: "ALEX SCOMPARIM - SABORES DE MINAS",
        rua: "Rua Herminia de Camargo Penteado",
        numero: "74",
        bairro: "Santa Terezinha",
        cep: "13360-706",
        telefone: "",
        whatsapp: "",
    },
    {
        estado: "SP",
        cidade: "Capivari",
        nome: "MERCADO SOUTO MONTEIRO",
        rua: "Rua Antônio Jarussi",
        numero: "26",
        bairro: "Vila Porto Alegre",
        cep: "13360-000",
        telefone: "",
        whatsapp: "",
    },
    {
        estado: "SP",
        cidade: "Capivari",
        nome: "PADARIA PANTOJO",
        rua: "Rua Maurício Allain",
        numero: "201",
        bairro: "Centro",
        cep: "",
        telefone: "",
        whatsapp: "",
    },
    {
        estado: "SP",
        cidade: "Capivari",
        nome: "BERENICE GCAL (FAZ DELIVERY)",
        rua: "",
        numero: "",
        bairro: "",
        cep: "",
        telefone: "",
        whatsapp: "",
    },

    // CERQUILHO
    {
        estado: "SP",
        cidade: "Cerquilho",
        nome: "PADARIA DO CHICÃO",
        rua: "",
        numero: "",
        bairro: "",
        cep: "",
        telefone: "",
        whatsapp: "",
    },
    {
        estado: "SP",
        cidade: "Cerquilho",
        nome: "PADARIA NOVA SÃO FRANCISCO",
        rua: "",
        numero: "",
        bairro: "",
        cep: "",
        telefone: "",
        whatsapp: "",
    },
    {
        estado: "SP",
        cidade: "Cerquilho",
        nome: "CAFÉ TUKÃN",
        rua: "",
        numero: "",
        bairro: "",
        cep: "",
        telefone: "",
        whatsapp: "",
    },

    // CESÁRIO LANGE
    {
        estado: "SP",
        cidade: "Cesário Lange",
        nome: "LÁ DA ROÇA",
        rua: "Av. 3 de Maio",
        numero: "1306",
        bairro: "Centro",
        cep: "18285-000",
        telefone: "",
        whatsapp: "",
    },

    // ELIAS FAUSTO
    {
        estado: "SP",
        cidade: "Elias Fausto",
        nome: "PADARIA CAFÉ REAL",
        rua: "",
        numero: "",
        bairro: "",
        cep: "",
        telefone: "",
        whatsapp: "",
    },

    // INDAIATUBA
    {
        estado: "SP",
        cidade: "Indaiatuba",
        nome: "EMPÓRIUM NORTE SUL",
        rua: "Av Engen. Fábio Roberto Barnabé",
        numero: "5838 - Sala2",
        bairro: "Jd. Morada do Sol",
        cep: "13346-443",
        telefone: "(19) 98205-0056 - Sérgio",
        whatsapp: "(11) 93363-8588 - Wesley",
    },
    {
        estado: "SP",
        cidade: "Indaiatuba",
        nome: "SERGIO ALEXANDRE LOPES (FAZ DELIVERY)",
        rua: "",
        numero: "",
        bairro: "",
        cep: "",
        telefone: "",
        whatsapp: "",
    },

    // ITU
    {
        estado: "SP",
        cidade: "Itu",
        nome: "SUPERMERCADO POTIGUARA",
        rua: "R. Hamleto Menquini",
        numero: "41",
        bairro: "Parque Res. Potiguara",
        cep: "13312-714",
        telefone: "",
        whatsapp: "",
    },
    {
        estado: "SP",
        cidade: "Itu",
        nome: "PANIFICADORA LÍDER",
        rua: "R. Ignácio Rodrigues D'Avila",
        numero: "268",
        bairro: "Vila Padre Bento",
        cep: "13313-020",
        telefone: "",
        whatsapp: "",
    },
    {
        estado: "SP",
        cidade: "Itu",
        nome: "DELÍCIAS DA ROÇA",
        rua: "Av. Galileu Bicudo",
        numero: "538",
        bairro: "Jardim Santana",
        cep: "13312-330",
        telefone: "",
        whatsapp: "",
    },
    {
        estado: "SP",
        cidade: "Itu",
        nome: "VALDO OVOS",
        rua: "Rua Décio Alves Siqueira",
        numero: "149",
        bairro: "Rancho Grande",
        cep: "13306-030",
        telefone: "",
        whatsapp: "",
    },
    {
        estado: "SP",
        cidade: "Itu",
        nome: "PADARIA CONSTÂNCIA",
        rua: "Praça Duque de Caxias",
        numero: "42 A",
        bairro: "Centro",
        cep: "13300-103",
        telefone: "",
        whatsapp: "",
    },
    {
        estado: "SP",
        cidade: "Itu",
        nome: "MERCADO JR",
        rua: "",
        numero: "",
        bairro: "",
        cep: "",
        telefone: "",
        whatsapp: "",
    },

    // PORTO FELIZ
    {
        estado: "SP",
        cidade: "Porto Feliz",
        nome: "LOJA MILK MENK",
        rua: "Av. José Maurino",
        numero: "239",
        bairro: "Centro",
        cep: "18540-000",
        telefone: "(15) 99819-4162",
        whatsapp: "",
    },
    {
        estado: "SP",
        cidade: "Porto Feliz",
        nome: "MERCEARIA JEQUITIBA",
        rua: "Rua Sapucaia",
        numero: "311",
        bairro: "Altos do Jequitibá",
        cep: "18540-000",
        telefone: "",
        whatsapp: "",
    },
    {
        estado: "SP",
        cidade: "Porto Feliz",
        nome: "REMIDOS MERCADO E PADARIA",
        rua: "Av. Governador Mário Covas",
        numero: "775",
        bairro: "Belo Alto",
        cep: "18546-228",
        telefone: "",
        whatsapp: "",
    },
    {
        estado: "SP",
        cidade: "Porto Feliz",
        nome: "EMPÓRIO FERRARI",
        rua: "Av. Iveta Gibim Alcalá",
        numero: "290",
        bairro: "Vila Alcalá",
        cep: "18546-028",
        telefone: "",
        whatsapp: "",
    },
    {
        estado: "SP",
        cidade: "Porto Feliz",
        nome: "ELEFANTÃO HORTIFRUTI",
        rua: "Rua Newton Prado",
        numero: "40",
        bairro: "Centro",
        cep: "18540-000",
        telefone: "",
        whatsapp: "",
    },
    {
        estado: "SP",
        cidade: "Porto Feliz",
        nome: "ÁBIU CEREALISTA EMPÓRIO",
        rua: "Av. Armando Sales de Oliveira",
        numero: "351",
        bairro: "Centro",
        cep: "18542-074",
        telefone: "",
        whatsapp: "",
    },
    {
        estado: "SP",
        cidade: "Porto Feliz",
        nome: "PADARIA RAINHA DO PORTO",
        rua: "R. Jéferson Soares de Souza",
        numero: "14",
        bairro: "Bambu",
        cep: "18540-542",
        telefone: "",
        whatsapp: "",
    },
    {
        estado: "SP",
        cidade: "Porto Feliz",
        nome: "MERCADOS CORREIA 1 E 2",
        rua: "",
        numero: "",
        bairro: "",
        cep: "",
        telefone: "",
        whatsapp: "",
    },
    {
        estado: "SP",
        cidade: "Porto Feliz",
        nome: "MERCADOS RHEMA 1 E 2",
        rua: "",
        numero: "",
        bairro: "",
        cep: "",
        telefone: "",
        whatsapp: "",
    },
    {
        estado: "SP",
        cidade: "Porto Feliz",
        nome: "EMPÓRIO MONÇÕES",
        rua: "",
        numero: "",
        bairro: "",
        cep: "",
        telefone: "",
        whatsapp: "",
    },
    {
        estado: "SP",
        cidade: "Porto Feliz",
        nome: "MERCEARIA GOMES",
        rua: "",
        numero: "",
        bairro: "",
        cep: "",
        telefone: "",
        whatsapp: "",
    },
    {
        estado: "SP",
        cidade: "Porto Feliz",
        nome: "ADEGA BREJA E CIA",
        rua: "",
        numero: "",
        bairro: "",
        cep: "",
        telefone: "",
        whatsapp: "",
    },
    {
        estado: "SP",
        cidade: "Porto Feliz",
        nome: "PANIFICADORA DOCE SABOR",
        rua: "",
        numero: "",
        bairro: "",
        cep: "",
        telefone: "",
        whatsapp: "",
    },
    {
        estado: "SP",
        cidade: "Porto Feliz",
        nome: "DOCE MONÇÕES",
        rua: "",
        numero: "",
        bairro: "",
        cep: "",
        telefone: "",
        whatsapp: "",
    },
    {
        estado: "SP",
        cidade: "Porto Feliz",
        nome: "MERCADO TABARRO",
        rua: "",
        numero: "",
        bairro: "",
        cep: "",
        telefone: "",
        whatsapp: "",
    },
    {
        estado: "SP",
        cidade: "Porto Feliz",
        nome: "SKINA DA GARAPA",
        rua: "Avenida Dr.Antonio Pires de Almeida",
        numero: "2380",
        bairro: "Cidade Jardim",
        cep: "",
        telefone: "",
        whatsapp: "",
    },
    {
        estado: "SP",
        cidade: "Porto Feliz",
        nome: "MERCEARIA DONA MARIA",
        rua: "",
        numero: "",
        bairro: "",
        cep: "",
        telefone: "",
        whatsapp: "",
    },

    // SOROCABA
    {
        estado: "SP",
        cidade: "Sorocaba",
        nome: "CASA DO PÃO CASEIRO EBENEZER",
        rua: "R. João Martins Fogaça",
        numero: "287",
        bairro: "Jardim Piazza Di Roma",
        cep: "18051-827",
        telefone: "",
        whatsapp: "",
    },
    {
        estado: "SP",
        cidade: "Sorocaba",
        nome: "TITA ROTISSERIE & EMPÓRIO",
        rua: "Rua Dr. Francisco Prestes Maia",
        numero: "171",
        bairro: "Vila Florinda",
        cep: "18040-650",
        telefone: "(15) 99713-6353",
        whatsapp: "",
    },
    {
        estado: "SP",
        cidade: "Sorocaba",
        nome: "CASA DOS OVOS DEUS É BOM",
        rua: "R. Juvenal de Paula Souza",
        numero: "346",
        bairro: "Novo Cajuru",
        cep: "18105-180",
        telefone: "(15) 99608-9429",
        whatsapp: "",
    },
    {
        estado: "SP",
        cidade: "Sorocaba",
        nome: "O PÃO DA VIZINHA",
        rua: "R. Cel. Nogueira Padilha",
        numero: "537",
        bairro: "Vila Hortência ",
        cep: "18020-000",
        telefone: "(15) 98139-2273",
        whatsapp: "",
    },
    {
        estado: "SP",
        cidade: "Sorocaba",
        nome: "FEIRA LIVRE - BANCA DOS FRIOS DO JUNIOR",
        rua: "R. Francisco Ferreira Leão",
        numero: "",
        bairro: "Vila Leão",
        cep: "18040-429",
        telefone: "",
        whatsapp: "",
    },
    {
        estado: "SP",
        cidade: "Sorocaba",
        nome: "FEIRA LIVRE - BANCA DOS FRIOS DO JUNIOR",
        rua: "R. Barão de Cotegipe",
        numero: "",
        bairro: "Vila Jardini",
        cep: "18040-420",
        telefone: "",
        whatsapp: "",
    },
    {
        estado: "SP",
        cidade: "Sorocaba",
        nome: "PADARIA E MERCADO COSTA",
        rua: "R. Libório Monaldo Stilitano",
        numero: "206",
        bairro: "Parque São Bento",
        cep: "18072-400",
        telefone: "",
        whatsapp: "",
    },
    {
        estado: "SP",
        cidade: "Sorocaba",
        nome: "MERCADO ALMEIDA",
        rua: "R. Alcino Oliveira Rosa",
        numero: "1116",
        bairro: "Parque São Bento",
        cep: "18072-660",
        telefone: "",
        whatsapp: "",
    },
    {
        estado: "SP",
        cidade: "Sorocaba",
        nome: "FEIRA LIVRE - VALDECI BARRACA DO QUEIJO",
        rua: "R. Benedita Conceição Gomes Perbelini",
        numero: "",
        bairro: "Jardim Guaíba",
        cep: "18077-110",
        telefone: "",
        whatsapp: "",
    },
    {
        estado: "SP",
        cidade: "Sorocaba",
        nome: "FEIRA LIVRE - VALDECI BARRACA DO QUEIJO",
        rua: "R. Fernando Antonio Guerner Camargo",
        numero: "",
        bairro: "Jardim Wanel Ville V",
        cep: "18057-081",
        telefone: "",
        whatsapp: "",
    },
    {
        estado: "SP",
        cidade: "Sorocaba",
        nome: "PADARIA ARTESANAL DA CLAUDIA",
        rua: "",
        numero: "",
        bairro: "",
        cep: "",
        telefone: "",
        whatsapp: "",
    },

    // SALTO
    {
        estado: "SP",
        cidade: "Salto",
        nome: "ALECRIM E TOMILHO",
        rua: "",
        numero: "",
        bairro: "",
        cep: "",
        telefone: "",
        whatsapp: "",
    },
    {
        estado: "SP",
        cidade: "Salto",
        nome: "MERCADO BARCELLI",
        rua: "",
        numero: "",
        bairro: "",
        cep: "",
        telefone: "",
        whatsapp: "",
    },
    {
        estado: "SP",
        cidade: "Salto",
        nome: "EMPÓRIO DO NORTE",
        rua: "",
        numero: "",
        bairro: "",
        cep: "",
        telefone: "",
        whatsapp: "",
    },
    {
        estado: "SP",
        cidade: "Salto",
        nome: "PADARIA ENCANTO DOS DOCES",
        rua: "",
        numero: "",
        bairro: "",
        cep: "",
        telefone: "",
        whatsapp: "",
    },

    // SÃO PAULO
    {
        estado: "SP",
        cidade: "São Paulo",
        nome: "ARMAZÉM MARIA AMÉLIA",
        rua: "Rua Valter Ribeiro Marrany",
        numero: "266",
        bairro: "Parque Alto do Rio Bonito",
        cep: "04810-010",
        telefone: "",
        whatsapp: "",
    },
    {
        estado: "SP",
        cidade: "São Paulo",
        nome: "ORESTRA MERCADO",
        rua: "Rua José Ganiolli",
        numero: "459",
        bairro: "Jardim Casablanca",
        cep: "05842-090",
        telefone: "",
        whatsapp: "",
    },

    // TIETÊ
    {
        estado: "SP",
        cidade: "Tietê",
        nome: "PADARIA SANTA TEREZINHA",
        rua: "Rua. Francisco de Toledo",
        numero: "230",
        bairro: "Bairro Caixa da Água",
        cep: "18530-000",
        telefone: "(15) 3282-3686",
        whatsapp: "",
    },
    {
        estado: "SP",
        cidade: "Tietê",
        nome: "DISTRIBUIDORA DE GÁS E BEBIDAS PIZOL",
        rua: "Rua Bom Jesus",
        numero: "812",
        bairro: "Centro",
        cep: "",
        telefone: "",
        whatsapp: "",
    },
  ];

  const [estadoSelecionado, setEstadoSelecionado] = useState("SP");
  const [cidadeSelecionada, setCidadeSelecionada] = useState("");
  const [cidades, setCidades] = useState([]);
  const [lojasFiltradas, setLojasFiltradas] = useState([]);

  useEffect(() => {
    const cidadesUnicas = [
      ...new Set(lojas.filter(loja => loja.estado === estadoSelecionado).map(loja => loja.cidade)),
    ];
    setCidades(cidadesUnicas);
    setCidadeSelecionada("");
    setLojasFiltradas([]);
  }, [estadoSelecionado]);

  const filtrarLojas = () => {
    if (estadoSelecionado && cidadeSelecionada) {
      const filtradas = lojas.filter(
        loja => loja.estado === estadoSelecionado && loja.cidade === cidadeSelecionada
      );
      setLojasFiltradas(filtradas);
    } else {
      setLojasFiltradas([]);
    }
  };

  useEffect(() => {
    filtrarLojas();
  }, [cidadeSelecionada]);

  // Estilos em formato de objeto
 

  return (
    <section className='Parceiros'>
        <div className='conteiner'>
            <form className='form'>
                <h4>Nossos Parceiros</h4>
                <h1>Revendedores</h1>
                <svg width="20" height="20" fill='red' stroke-width="1"><use href="/icons.svg#Target"></use></svg>

                {/* Filtros */}
                <select
                    value={estadoSelecionado}
                    onChange={(e) => setEstadoSelecionado(e.target.value)}
                    className='select'
                >
                    <option value="" disabled>
                    Selecione o Estado
                    </option>
                    <option value="SP">SP</option>
                    {/* <option value="RJ">RJ</option> */}
                </select>

                <select
                    value={cidadeSelecionada}
                    onChange={(e) => setCidadeSelecionada(e.target.value)}
                    disabled={cidades.length === 0}
                    className='select'
                >
                    <option value="" disabled>Selecione a Cidade</option>
                    {cidades.map((cidade) => (
                    <option key={cidade} value={cidade}>
                        {cidade}
                    </option>
                    ))}
                </select>

                
            </form>

            {/* Contêiner de lojas */}
            <div id="lojas">
                {lojasFiltradas.map((loja, index) => (
                <div key={index} className="loja">
                    <div className='icon'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 24 24">
                            <path fill="#3b918c" fill-rule="evenodd" d="M11 3.055A9.004 9.004 0 0 0 3.055 11H0v2h3.055A9.004 9.004 0 0 0 11 20.945V24h2v-3.055A9.004 9.004 0 0 0 20.945 13H24v-2h-3.055A9.004 9.004 0 0 0 13 3.055V0h-2zM12 16a4 4 0 1 0 0-8a4 4 0 0 0 0 8m0-2a2 2 0 1 1 0-4a2 2 0 0 1 0 4" clip-rule="evenodd"/>
                        </svg>
                    </div>
                    <div className='conteudo'>
                        <h3><strong>{loja.nome}</strong></h3>
                        
                        { loja.rua && <p className='endereco'>
                            <strong>Endereço:</strong>
                            { loja.rua && loja.numero && <span>{loja.rua}, {loja.numero}</span> }
                            { loja.bairro && <span>{loja.bairro} - {loja.cidade}/{loja.estado}</span> }
                            { loja.cep && <span>{loja.cep}</span> }
                        </p> }

                        { loja.telefone && <p className='telefone'><strong>Telefone:</strong><span>{loja.telefone}</span></p> }
                        { loja.whatsapp && <p className='whatsapp'><strong>Telefone:</strong><span>{loja.whatsapp}</span></p> }
                    </div>

                    <div className='icon'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 32 32"><path fill="#cccccc" d="M16 3C8.8 3 3 8.8 3 16s5.8 13 13 13s13-5.8 13-13c0-1.4-.188-2.794-.688-4.094L26.688 13.5c.2.8.313 1.6.313 2.5c0 6.1-4.9 11-11 11S5 22.1 5 16S9.9 5 16 5c3 0 5.694 1.194 7.594 3.094L25 6.688C22.7 4.388 19.5 3 16 3m11.28 4.28L16 18.563l-4.28-4.28l-1.44 1.437l5 5l.72.686l.72-.687l12-12l-1.44-1.44z"></path></svg>
                    </div>

                </div>
                ))}
            </div>
        </div>
    </section>
    
  );
};

export default App;
